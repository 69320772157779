<template>
  <b-container class="directory-content">
    <b-row align-v="center">
      <b-col cols="8" class="vocabulary-hints">
        <span class="iconfont icon-tishi"></span>
        系统已为你自动定位不同题型的训练难度等级，请根据系统指引训练即可！
      </b-col>
      <b-col>
        <div class="text-right" v-show="permission.indexOf('Plys.English.MiddleSchool.CanSkip') !== -1">
          <GlobalButtonParallelogram
            :sprintId="2"
            :tagId="2"
          ></GlobalButtonParallelogram>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="text-left table-tag">词汇</b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-table
          striped
          :fields="fields"
          :items="items"
          table-class="vocabulary-table"
          thead-tr-class="form-thead"
          tbody-class="form-tbody"
        >
          <template v-slot:cell(trainingExamName)="row">
            <div class="estimated-time">{{ row.value }}</div>
          </template>
          <template v-slot:cell(seven)="row">
            <GlobalButtonDirectory
              :state="row.value.state"
              :starNum="row.value.star"
              @beginTest="beginTest(row)"
            ></GlobalButtonDirectory>
          </template>
          <template v-slot:cell(eighthUp)="row">
            <GlobalButtonDirectory
              :state="row.value.state"
              :starNum="row.value.star"
              @beginTest="beginTest(row)"
            ></GlobalButtonDirectory>
          </template>
          <template v-slot:cell(eighthDown)="row">
            <GlobalButtonDirectory
              :state="row.value.state"
              :starNum="row.value.star"
              @beginTest="beginTest(row)"
            ></GlobalButtonDirectory>
          </template>
          <template v-slot:cell(nine)="row">
            <GlobalButtonDirectory
              :state="row.value.state"
              :starNum="row.value.star"
              @beginTest="beginTest(row)"
            ></GlobalButtonDirectory>
          </template>
          <template v-slot:cell(expectedMinutes)="row">
            <div class="estimated-time">{{ row.value }} 分钟</div>
          </template>
          <template v-slot:cell(actualMinutes)="row">
            <div class="estimated-time">{{ row.value }} 分钟</div>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <b-row align-h="end" v-show="permission.indexOf('Plys.English.MiddleSchool.CanSkip') !== -1">
      <b-col cols="2" class="go-grammar-training" @click="clickJumpSprint"
        >进入语法训练</b-col
      >
    </b-row>
    <GlobalPopup
      :popupsTitle="porpContent.popupsTitle"
      :popupsbuttomA="porpContent.popupsbuttomA"
      :popupsbuttomB="porpContent.popupsbuttomB"
      @OKbutton="okButton"
      @NObutton="
        () => {
          modalShow = false;
        }
      "
      :isShow="modalShow"
    ></GlobalPopup>
  </b-container>
</template>

<script>
import { getVocabularyTraining, jumpSprint } from "@/api/english-small";
import GlobalButtonDirectory from "@/components/global-button/global-button-directory.vue";
import GlobalButtonParallelogram from "@/components/global-button/global-button-parallelogram.vue";
import GlobalPopup from "@/components/global-popup/global-popup-modal";
import { mapState,mapMutations } from "vuex";
export default {
  components: { GlobalButtonDirectory, GlobalButtonParallelogram, GlobalPopup },
  data() {
    return {
      tagId: 1,
      fields: [
        {
          key: "trainingExamName",
          label: "",
        },
        {
          key: "seven",
          label: "七年级",
        },
        {
          key: "eighthUp",
          label: "八年级上",
        },
        {
          key: "eighthDown",
          label: "八年级下",
        },
        {
          key: "nine",
          label: "九年级",
        },
        {
          key: "expectedMinutes",
          label: "预期时间",
        },
        {
          key: "actualMinutes",
          label: "实际时间",
        },
      ],
      items: [],
      porpContent: "",
      modalShow: false,
    };
  },
  created() {
    this.getDirectoryData();
  },
  computed: {
    ...mapState([
      "permission",
    ]),
  },
  methods: {
    ...mapMutations(["setTrainingData"]),
    getDirectoryData() {
      getVocabularyTraining({}).then((res) => {
        console.log("词汇目录", res);
        this.items = res.data.result.vocabularyTrainings;
        this.tagId = res.data.result.tagId;
      });
    },
    toSprintTest() {
      this.$router.push({
        name: "SmallModuleSprintTest",
      });
    },
    getRow(row) {
      console.log("rowshuju", row);
      return "";
    },
    beginTest(row) {
      console.log("row", row);
      // debugger
      let trainingData = {
        tagId: this.tagId,
        grammerTagId: "",
        setTagId: row.item.questionSet,
        difficultyTagId: row.value.tagId,
        summaryId: row.value.summaryId,
      };
      this.setTrainingData(trainingData);
      this.$router.push({
        name: "SmallModuleSprintTest",
        query: {
          trainingExamId: row.item.trainingExamId,
          sprintId: 2,
          // module: 1,
          type: 1,
        },
      });
    },
    clickJumpSprint() {
      this.porpContent = {
        popupsTitle:
          "确定跳过词汇部分的训练吗？确认后将取消词汇剩余试题的作答和培优币奖励，并无法返回继续训练！",
        popupsbuttomA: "不跳过",
        popupsbuttomB: "跳过",
      };
      this.modalShow = true;
      this.okButton = () => {
        this.modalShow = false;
        this.vocabularySkip();
      };
    },
    okButton() {},
    //词汇完成
    vocabularySkip() {
      jumpSprint({ sprintId: 2, tagId: "1" }).then(() => {
        this.$router.replace({
          name: "HomeIndex",
        });
      });
    },
    afterClick() {
      this.porpContent = {
        popupsTitle:
          "选择跳过本知识模块吗？确认后将取消本模块剩余试题的培优币奖励，并无法返回继续训练!",
        popupsbuttomA: "不跳过",
        popupsbuttomB: "跳过",
      };
      this.modalShow = true;
      this.okButton = () => {
        this.modalShow = false;
        this.moduleSkip();
      };
    },
    // 小模块完成
    moduleSkip() {
      jumpSprint({ sprintId: 2, tagId: "2" }).then(() => {
        this.$router.replace({
          name: "HomeIndex",
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.directory-content {
  background: white;
  min-height: 500px;
  padding: 15px;
  .vocabulary-hints {
    text-align: left;
    color: #2cb774;
    font-weight: bold;
    font-size: 14px;
  }
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.table-tag {
  font-weight: bold;
  margin: 10px;
  font-size: 16px;
}
.estimated-time {
  padding: 1.3rem 1rem;
}
.go-grammar-training {
  color: #2cb774;
  // font-weight: bold;
  cursor: pointer;
  text-decoration: underline;
}
</style>
<style lang="scss">
.directory-content {
  .form-thead {
    background-color: #2cb774;
    color: #fff;
    font-size: 16px;
  }
  .form-tbody,
  .btn-outline-secondary {
    color: #666;
  }
  .table-striped tbody tr:nth-of-type(odd) {
    background-color: #fff;
  }
  .table-striped tbody tr:nth-of-type(even) {
    background-color: #eef9f4;
  }
  .table th,
  .table td {
    border: none;
  }
  .btn-outline-secondary:hover {
    background-color: transparent;
  }
}
</style>